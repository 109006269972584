.k-input-flat,
.k-picker-flat {
    &.k-rounded-full {
        border-radius: 16px 16px 0 0;
    }
}

.readonly kendo-textbox  {
    background: $readonly-field-bg;
    @extend .w-font-weight-bold;
    @extend .remove-border;
}

.k-input-sm, .k-picker-sm {
    height:$input-height-sm;
}

.k-input-md, .k-picker-md {
    height:$input-height-md;
}

.k-input-lg, .k-picker-lg {
    height:$input-height-lg;
}

.k-input-md .k-input-values, .k-picker-md .k-input-values {
    padding: 0 map-get($spacing, 1);
}


kendo-textarea.k-input-sm,
kendo-textarea.k-input-md,
kendo-textarea.k-input-lg {
    height: auto !important;
}