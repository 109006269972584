$kendo-treeview-bg: null !default;
$kendo-treeview-text: $primary !default;
$kendo-treeview-text-secondary: $component-text !default;
$kendo-treeview-border: null !default;
$kendo-treeview-item-hover-bg: $hovered-bg !default;
$kendo-treeview-item-hover-bg-secondary: rgba(15, 34, 88, 0.04) !default;
$kendo-treeview-item-hover-text: $primary !default;
$kendo-treeview-item-hover-text-secondary: $component-text !default;
$kendo-treeview-item-hover-border: null !default;
$kendo-treeview-item-hover-gradient: null !default;
$kendo-treeview-item-selected-bg: $primary !default;
$kendo-treeview-item-selected-text: $component-text-white !default;
$kendo-treeview-item-selected-border: null !default;
$kendo-treeview-item-selected-gradient: null !default;
$kendo-treeview-item-focus-shadow: inset 0px 0px 0px 2px rgba(0, 151, 233, 0.3) !default;
$kendo-treeview-indent: 16px !default;
$kendo-treeview-item-padding-x: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-x-lg: map-get( $spacing, 2 ) !default;
$kendo-treeview-item-padding-y: map-get( $spacing, thin ) !default;
$kendo-treeview-item-padding-y-sm: map-get( $spacing, thin ) - map-get( $spacing, thin ) !default;
$kendo-treeview-item-padding-y-md: map-get( $spacing, thin ) !default;
$kendo-treeview-item-padding-y-lg: map-get( $spacing, 1 ) !default;
$kendo-treeview-item-border-width: 0px !default;
$kendo-treeview-item-border-radius: 4px !default;
$treeview-selected-button-width:236px;
// Treeview

/// Font family of the treeview component.
// /// @group treeview
// $kendo-treeview-font-family: $font-family !default;
// /// Font size of the treeview component.
// /// @group treeview
// $kendo-treeview-font-size: $font-size-md !default;
// $kendo-treeview-font-size-sm: $font-size-md !default;
// $kendo-treeview-font-size-md: $font-size-md !default;
// $kendo-treeview-font-size-lg: $font-size-lg !default;
/// Line height of the treeview component.
// @group treeview
// $kendo-treeview-line-height: $line-height-md !default;
// $kendo-treeview-line-height-sm: $line-height-md !default;
$kendo-treeview-line-height-md: $line-height-md !default;
// $kendo-treeview-line-height-lg: $line-height-lg !default;
/// Indentation of child groups in treeview component.
/// The sizes of the treeview.
// /// @group treeview
// $kendo-treeview-sizes: (
//     sm: (
//         font-size: $kendo-treeview-font-size-sm,
//         line-height: $kendo-treeview-line-height-sm,
//         item-padding-x: $kendo-treeview-item-padding-x-sm,
//         item-padding-y: $kendo-treeview-item-padding-y-sm
//     ),
//     md: (
//         font-size: $kendo-treeview-font-size-md,
//         line-height: $kendo-treeview-line-height-md,
//         item-padding-x: $kendo-treeview-item-padding-x-md,
//         item-padding-y: $kendo-treeview-item-padding-y-md
//     ),
//     lg: (
//         font-size: $kendo-treeview-font-size-lg,
//         line-height: $kendo-treeview-line-height-lg,
//         item-padding-x: $kendo-treeview-item-padding-x-lg,
//         item-padding-y: $kendo-treeview-item-padding-y-lg
//     )
// ) !default;


/// Background color of the treeview component.
/// Background color of load more.
// @group treeview
// $kendo-treeview-loadmore-bg: transparent !default;
// /// Text color of load more.
// /// @group treeview
// $kendo-treeview-loadmore-text: $link-text !default;
// /// Border color of load more.
// /// @group treeview
// $kendo-treeview-loadmore-border: null !default;

// /// Background color of a hovered load more.
// /// @group treeview
// $kendo-treeview-loadmore-hover-bg: transparent !default;
// /// Text color of a hovered load more.
// /// @group treeview
// $kendo-treeview-loadmore-hover-text: $link-hover-text !default;
// /// Border color of a hovered load more.
// /// @group treeview
// $kendo-treeview-loadmore-hover-border: null !default;

// /// Background color of a focused load more.
// /// @group treeview
// $kendo-treeview-loadmore-focus-bg: transparent !default;
// /// Text color of a focused load more.
// /// @group treeview
// $kendo-treeview-loadmore-focus-text: $link-hover-text !default;
// /// Border color of a focused load more.
// /// @group treeview
// $kendo-treeview-loadmore-focus-border: null !default;
// /// Box shadow of a focused load more.
// /// @group treeview
// $kendo-treeview-loadmore-focus-shadow: $kendo-treeview-item-focus-shadow !default;
