// Expansion panel
// $expander-spacing-y: $padding-y * 2 !default;
// $expander-font-family: $font-family !default;
// $expander-font-size: $font-size !default;
// $expander-line-height: $line-height !default;
// $expander-border-width: 1px !default;

$expander-header-padding-x: 0 !default;
$expander-header-padding-y: 0 !default;

// $expander-indicator-margin-x: $padding-x * 1.5 !default;

$expander-bg: transparent !default;
// $expander-text: $component-text !default;
$expander-border: transparent !default;

$expander-focus-shadow: none !default;

// $expander-header-bg: transparent !default;
// $expander-header-text: $expander-text !default;
// $expander-header-border: null !default;

$expander-header-hover-bg: none !default;

// $expander-header-focused-bg: null !default;
// $expander-header-focused-shadow: $kendo-list-item-focus-shadow !default;

// $expander-title-text: $primary !default;

// $expander-header-sub-title-text: $subtle-text !default;

$expander-content-padding-x: 0 !default;
$expander-content-padding-y: 0 !default;
