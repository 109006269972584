// Switch

/// Font family of the switch.
/// @group switch
// $kendo-switch-font-family: null !default;

/// Border width of the switch track.
/// @group switch
// $kendo-switch-track-border-width: 1px !default;

/// Border width of the switch thumb.
/// @group switch
// $kendo-switch-thumb-border-width: 1px !default;

/// Text transform of the switch label.
/// @group switch
// $kendo-switch-label-text-transform: uppercase !default;
/// Display of the switch label.
/// @group switch
// $kendo-switch-label-display: inline !default;

/// Map with the different switch sizes.
/// @group switch
$kendo-switch-sizes: (
    sm: ( font-size: 10px, track-width: 52px, track-height: 26px, thumb-width: 26px, thumb-height: 26px, thumb-offset: 0px, label-offset: 4px ),
    md: ( font-size: 10px, track-width: 64px, track-height: 32px, thumb-width: 32px, thumb-height: 32px, thumb-offset: 0px, label-offset: 6px ),
    lg: ( font-size: 10px, track-width: 68px, track-height: 34px, thumb-width: 34px, thumb-height: 34px, thumb-offset: 0px, label-offset: 8px )
) !default;

/// The background of the track when the switch is not checked.
/// @group switch
// $kendo-switch-off-track-bg: $component-bg !default;
/// The text color of the track when the switch is not checked.
/// @group switch
$kendo-switch-off-track-text: $secondary !default;
/// The border color of the track when the switch is not checked.
/// @group switch
$kendo-switch-off-track-border: $secondary !default;
/// The background gradient of the track when the switch is not checked.
/// @group switch
// $kendo-switch-off-track-gradient: null !default;

/// The background of the track when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-track-hover-bg: null !default;
/// The text color of the track when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-track-hover-text: null !default;
/// The border color of the track when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-track-hover-border: null !default;
/// The background gradient of the track when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-track-hover-gradient: null !default;

/// The background of the track when the focused switch is not checked.
/// @group switch
// $kendo-switch-off-track-focus-bg: null !default;
/// The text color of the track when the focused switch is not checked.
/// @group switch
// $kendo-switch-off-track-focus-text: null !default;
/// The border color of the track when the focused switch is not checked.
/// @group switch
// $kendo-switch-off-track-focus-border: null !default;
/// The background gradient of the track when the focused switch is not checked.
/// @group switch
// $kendo-switch-off-track-focus-gradient: null !default;
/// The ring around the track when the focused switch is not checked.
/// @group switch
$kendo-switch-off-track-focus-ring: 0 !default;

/// The background of the track when the disabled switch is not checked.
/// @group switch
// $kendo-switch-off-track-disabled-bg: null !default;
/// The text color of the track when the disabled switch is not checked.
/// @group switch
// $kendo-switch-off-track-disabled-text: null !default;
/// The border color of the track when the disabled switch is not checked.
/// @group switch
// $kendo-switch-off-track-disabled-border: null !default;
/// The background gradient of the track when the disabled switch is not checked.
/// @group switch
// $kendo-switch-off-track-disabled-gradient: null !default;

/// The background of the thumb when the switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-bg: $base-bg !default;
/// The text color of the thumb when the switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-text: $base-text !default;
/// The border color of the thumb when the switch is not checked.
/// @group switch
$kendo-switch-off-thumb-border: $secondary !default;
/// The background gradient of the thumb when the switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-gradient: null !default;

/// The background of the thumb when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered switch is not checked.
/// @group switch
// $kendo-switch-off-thumb-hover-gradient: null !default;


/// The background of the track when the switch is checked.
/// @group switch
$kendo-switch-on-track-bg: $success !default;
/// The text color of the track when the switch is checked.
/// @group switch
$kendo-switch-on-track-text: $white !default;
/// The border color of the track when the switch is checked.
/// @group switch
// $kendo-switch-on-track-border: $kendo-switch-on-track-bg !default;
/// The background gradient of the track when the switch is checked.
/// @group switch
// $kendo-switch-on-track-gradient: null !default;

/// The background of the track when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-track-hover-bg: null !default;
/// The text color of the track when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-track-hover-text: null !default;
/// The border color of the track when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-track-hover-border: null !default;
/// The background gradient of the track when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-track-hover-gradient: null !default;

/// The background of the track when the focused switch is checked.
/// @group switch
// $kendo-switch-on-track-focus-bg: null !default;
/// The text color of the track when the focused switch is checked.
/// @group switch
// $kendo-switch-on-track-focus-text: null !default;
/// The border color of the track when the focused switch is checked.
/// @group switch
// $kendo-switch-on-track-focus-border: null !default;
/// The background gradient of the track when the focused switch is checked.
/// @group switch
// $kendo-switch-on-track-focus-gradient: null !default;
/// The ring around the track when the focused switch is checked.
/// @group switch
$kendo-switch-on-track-focus-ring: 0 !default;

/// The background of the track when the disabled switch is checked.
/// @group switch
// $kendo-switch-on-track-disabled-bg: null !default;
/// The text color of the track when the disabled switch is checked.
/// @group switch
// $kendo-switch-on-track-disabled-text: null !default;
/// The border color of the track when the disabled switch is checked.
/// @group switch
// $kendo-switch-on-track-disabled-border: null !default;
/// The background gradient of the track when the disabled switch is checked.
/// @group switch
// $kendo-switch-on-track-disabled-gradient: null !default;

/// The background of the thumb when the switch is checked.
/// @group switch
// $kendo-switch-on-thumb-bg: $base-bg !default;
/// The text color of the thumb when the switch is checked.
/// @group switch
// $kendo-switch-on-thumb-text: $base-text !default;
/// The border color of the thumb when the switch is checked.
/// @group switch
$kendo-switch-on-thumb-border: $success !default;
/// The background gradient of the thumb when the switch is checked.
/// @group switch
// $kendo-switch-on-thumb-gradient: null !default;

/// The background of the thumb when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-thumb-hover-bg: null !default;
/// The text color of the thumb when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-thumb-hover-text: null !default;
/// The border color of the thumb when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-thumb-hover-border: null !default;
/// The background gradient of the thumb when the hovered switch is checked.
/// @group switch
// $kendo-switch-on-thumb-hover-gradient: null !default;

$switch-track-focus-shadow: 0px 0px 0px 2px rgba($secondaryblue, 0.3) !default;