.mask-hover {
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $hovered-bg;
    z-index: 2;
    pointer-events: none;
  }
}

.k-grid {
  border-top-left-radius: $grid-wrapper-border-radius;
  border-top-right-radius: $grid-wrapper-border-radius;
  overflow: hidden;
  th {
    font-weight: $font-weight-bold;
    line-height: map-get($spacing, 5);
  }
}
.k-checkbox {
  border-radius: 2px;
}
.k-menu-button,
.k-dropdown-button .k-icon {
  color: $component-text;
}

.k-group-indicator {
  border-color: $primary;
  background: $primary;
  color: $white;
}

.k-filter-menu-container {
  .k-picker-solid {
    color: $component-text;
    background: $component-text-white;
    border-radius: $kendo-border-radius-md;
    min-width: 84px;
  }
  .k-input-solid {
    border-radius: $kendo-border-radius-md;
  }
}

.k-columnmenu-actions {
  display: flex;
  justify-content: center;
  button {
    width: 100%;
    font-weight: $font-weight-bold;
    &:nth-child(1) {
      border: 1px solid $primary;
      background: $component-text-white;
      color: $primary;
    }
  }
  * {
    border-radius: $kendo-border-radius-md;
  }
}

// .k-i-sort-asc-small::before {
//   content: url(../../../../images/arrowUpwardOutlined.svg);
//   scale: 0.7;
//   color: $primary;
// }
// .k-i-sort-desc-small::before {
//   content: url(../../../../images/arrowDownwardOutlined.svg);
//   scale: 0.7;
//   color: $primary;
// }
// .k-i-filter::before {
//   content: url(../../../../images/filterOutlined.svg);
//   scale: 0.7;
//   color: $primary;
// }

.k-grid-content-sticky.sticky-right-shadow,
.k-grid-header-sticky.sticky-right-shadow {
  filter: $grid-sticky-right-shadow;
}

td:not(.k-grid-content-sticky) + .k-grid-content-sticky,
th:not(.k-grid-header-sticky) + .k-grid-header-sticky {
  filter: $grid-sticky-left-shadow;
}

.k-footer-template {
  td, .k-grid-footer-sticky {
    border-left-width: 0;
    border-right-width: 0;
    &:has(.footer-aggregate), &:has(.footer-aggregate) + td {
      border-left-width: 1px;
    }
  }
}

.k-filtercell-operator {
  .k-picker-solid {
    border: none;
    background: transparent;
  }

  .k-button-solid-base {
    background: transparent;
    border: none;
  }
}

.extended-grid-wrapper, .grid-card {
  filter: drop-shadow(0px 4px 8px rgba(15, 34, 88, 0.04)) drop-shadow(0px 4px 16px rgba(15, 34, 88, 0.08));
  border-radius: $grid-wrapper-border-radius;
  background-color: $component-bg;
  overflow: hidden;
}

.grid-footer:empty {
  @extend .w-p-0;
}

td {
  .pills {
    border-radius: $kendo-border-radius-sm;
    padding: map-get($spacing, thin) map-get($spacing, 2);
  }
  &.pills-teal .grid-cell {
    @extend .pills;
    background-color: $pills-teal-bg;
  }

  &.pills-purple .grid-cell {
    @extend .pills;
    background-color: $pills-purple-bg;
  }

  &.pills-success .grid-cell {
    @extend .pills;
    background-color: $pills-succss-bg;
  }

  &.pills-teal, &.pills-purple, &.pills-success {
    padding-top: map-get($spacing, thin);
    padding-bottom: map-get($spacing, thin);
  }
}

.k-grid td.k-command-cell {
  padding-top: map-get($spacing, thin);
  padding-bottom: map-get($spacing, thin);
}

// .k-master-row.k-selected .k-grid-content-sticky,
// .k-master-row.k-selected .k-grid-row-sticky,
// .k-master-row td.k-grid-content-sticky.k-selected {
//   background-color: $grid-selected !important;
// }

.k-grid {
  tr {
    &.row-selected {
      background: $grid-selected;
    }
    
    &.row-highlighted {
      td{
        position:relative;
        z-index: 1;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $grid-highlighted;
            z-index: 2;
            pointer-events: none;
          }
      }
    }
  }
}

.error-column {
  color: $error;
}

.grid-loader-container {
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-ellipsis-overflow{
  text-overflow: initial;
  white-space: initial;
  overflow: initial;
}

th.wrap-title-overflow {
  &.k-header {
    vertical-align: middle;
  }
  .k-column-title {
    @extend .text-ellipsis-overflow;
  }
}

td.wrap-text-overflow {
  .text-ellipsis {
    @extend .text-ellipsis-overflow;
  }
}

th.vertical-align-top{
  vertical-align: top !important;
  .k-cell-inner{
    align-items: start;
  }
}

// Common styles for PDF export

kendo-pdf-document {
  .k-grid-header .k-header {
    vertical-align: top;
  }
  th .k-column-title {
    text-overflow: initial;
    white-space: initial;
    overflow: initial;
  }

  td {
    word-break: break-all;
    .text-ellipsis {
      text-overflow: initial;
      white-space: initial;
      overflow: initial;
    }
  }
}

th.text-center {
  .k-link {
    justify-content: center;
  }
}

.k-group-footer td {
  background-color: $white;
}

// Styles when column group is applied
.odd-group-header {
  background-color: $odd-group-header-bg;
  color: $odd-group-header-text;
}

.odd-group-cell {
  background-color: $odd-group-cell-bg;
}

.even-group-header {
  background-color: $even-group-header-bg;
  color: $even-group-header-text;
}

.even-group-cell {
  background-color: $even-group-cell-bg;
}

.k-group-footer td:has(.no-td-padding) {
  padding: 0;
}

.k-group-footer td:has(.even-group-cell-content) {
  background-color: $even-group-cell-bg;
}

.k-group-footer td:has(.odd-group-cell-content) {
  background-color: $odd-group-cell-bg;
}

.k-grid .k-group-footer td.k-group-cell {
  border-top-width: 0;
}

.k-group-footer td:has(.group-footer-content), .k-group-footer td:has(.group-footer-content) + td {
  border-left-width: $grid-cell-vertical-border-width;
}

.border-all-footer-cells {
  .k-grid-footer td, .k-group-footer td {
    border-left-width: $grid-cell-vertical-border-width !important;
    &:first-child {
      border-left-width: 0 !important;
    }
  }

  .k-grid-content tr:last-child > td {
    border-bottom-width: $grid-cell-vertical-border-width;
  }
}



kendo-grid tr:not(.k-detail-row):hover , 
kendo-grid tr:not(.k-detail-row).k-hover,
.k-master-row:hover,
.k-master-row.k-hover,
.k-grid tbody>tr:not(.k-detail-row):hover,
.k-grid tbody>tr:not(.k-detail-row).k-hover {
  &:not(.k-footer-template) {
    &:not(.k-selected) {
      .k-grid-content-sticky, td:not(.k-group-cell) {
        background-color: $grid-bg;
        @extend .mask-hover;
      }
    
      &.k-alt {
        .k-grid-content-sticky, td:not(.k-group-cell) {
          background-color: $grid-alt-bg;
        }
      }
      
      td:not(.k-grid-content-sticky) {
        position: relative;
      }
    }
  }
}

kendo-grid tr:not(.k-detail-row):not(.k-footer-template) , 
.k-master-row
.k-grid tbody>tr:not(.k-detail-row):not(.k-footer-template) {
  &.k-selected {
    td:not(.k-group-cell), .k-grid-content-sticky {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 151, 233, 0.16);
        z-index: 2;
        pointer-events: none;
      }
    }
    td:not(.k-grid-content-sticky) {
      position: relative;
    }
  }
  &.k-selected.k-alt {
    td, .k-grid-content-sticky {
      background-color: $grid-alternate !important;
    }
  }
}


.extended-grid-wrapper, .grid-wrapper {
  &.is-readonly {
    tr.k-master-row {
      td:not(.k-group-cell), .k-grid-content-sticky {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: rgba(127, 133, 220, 0.08);
        }
      }
      td:not(.k-grid-content-sticky) {
        position: relative;
      }
    }
  }

  &.hide-column-footer {
    .k-grid-footer {
      display: none;
    }
  }
}

.k-footer-template {
  pointer-events: none;
}


kendo-grid .k-grouping-row , kendo-grid .k-grouping-header {
  background-color: $white !important;
}

kendo-grid .k-grouping-row td, tbody .k-group-cell {
  background-color: $white;
}

.k-command-cell williams-ui-platform-dropdown-button button {
  padding: map-get($spacing, 1) !important;
  font-size: $font-size-lg;
  line-height: 1;
  border-color: $component-border;
  &:hover {
    border-color: $component-border;
    background-color: transparent;
  }
  .k-icon {
    font-size: inherit;
  }
}

.k-grid .k-grid-edit-row td {
  padding-top: map-get($spacing, thin);
  padding-bottom: map-get($spacing, thin);
}

.k-grid-footer td {
  padding-top: map-get($spacing, 2) - map-get($spacing, thin);
  padding-bottom: map-get($spacing, 2) - map-get($spacing, thin);
  .footer-aggregate {
    font-size: $font-size-xs;
    line-height: 16px;
  }
}

.grid-footer {
  border: 1px solid $component-border;
  border-top-width: 0;
  border-bottom-left-radius: $grid-wrapper-border-radius;
  border-bottom-right-radius: $grid-wrapper-border-radius;
}

kendo-grid-group-panel {
  &.k-grouping-header-flex {
    gap: 4px;
  }
  
  .k-group-indicator {
    border-radius: 32px;
    @extend .w-small-text-normal;
  }
}

kendo-grid th.k-hierarchy-cell {
  border-bottom: 0;
}

.extended-grid-wrapper.row-count-hidden {
  .k-grid {
    border-bottom-left-radius: $grid-wrapper-border-radius;
    border-bottom-right-radius: $grid-wrapper-border-radius;
  }
}

.k-grid .k-hierarchy-cell > .k-icon {
  padding: map-get($spacing, 1) map-get($spacing, 0);
}

kendo-grid kendo-grid-group-panel {
  height: map-get($spacing, 10);
  &.k-grouping-header:before {
    margin-left: -4px;
    height: map-get($spacing, 6);
  }
  .k-indicator-container {
    margin: map-get($spacing, 0) !important;
    padding: map-get($spacing, 0) !important;
    .k-group-indicator {
      height: map-get($spacing, 6);
      .k-link {
        @extend .w-small-text-normal;
      }
      .k-icon-button {
        @extend .w-small-text-normal;
        height: 16px;
        .k-button-icon {
          width: 16px;
        }
      }
    }
  }
}

tr:nth-child(even).amber-color-row-indicator td {
    background: $amber-row-color;
}

tr:nth-child(odd).amber-color-row-indicator td {
  background: $amber-row-alt-color;
}
