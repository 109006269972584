// // Upload
$upload-border-width: 1px !default;
// $upload-font-family: $font-family !default;
// $upload-font-size: $font-size !default;
// $upload-line-height: $line-height !default;
// $upload-max-height: 300px !default;

$upload-bg: $component-bg !default;
$upload-text: $component-text !default;
// $upload-border: $component-bg !default;

// $upload-dropzone-padding-x: map-get( $spacing, 2 ) !default;
// $upload-dropzone-padding-y: map-get( $spacing, 2 ) !default;
$upload-dropzone-bg: $component-bg !default;
$upload-dropzone-text: $component-text  !default;
// $upload-dropzone-border: $upload-border !default;
$upload-dropzone-hover-bg: $hovered-bg !default;

$upload-status-text: $secondary !default;
// $upload-status-text-opacity: null !default;

// $upload-item-padding-x: map-get( $spacing, 2 ) !default;
// $upload-item-padding-y: map-get( $spacing, 2 ) !default;

// $upload-multiple-items-spacing: 12px !default;

// $upload-validation-font-size: 11px !default;
// $upload-icon-spacing: $icon-spacing !default;
$upload-icon-color: $subtle-text !default;

// $upload-item-image-width: 30px !default;
// $upload-item-image-height: 30px !default;
// $upload-item-image-border: 0px !default; // TODO: remove

// $upload-group-icon-size: $icon-size-lg !default;

// $upload-progress-thickness: 2px !default;
$upload-progress-bg: $info !default;
$upload-progress-text: $component-text !default;

$upload-success-bg: $success !default;
$upload-success-text: $success !default;

$upload-error-bg: $error !default;
$upload-error-text: $error !default;
$upload-error-border: $error !default;

$upload-focused-shadow: 0 0 0 2px rgba(0, 0, 0, .13) !default;

$upload-border-radius:map-get($spacing,1);