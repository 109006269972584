.k-notification-group{
    @extend .w-mt-15
}

.k-notification-wrap {
    align-items: center;
    gap: 8px;

    .k-notification-content {
        @extend .w-base-text-bold;
    }
}