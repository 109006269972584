// Badge

/// Width of the border around the badge.
/// @group badge
// $kendo-badge-border-width: 1px !default;

/// Border radius of the badge.
/// @group badge
// $kendo-badge-border-radius: $kendo-border-radius-md !default;


/// Horizontal padding of the badge.
/// @group badge
$kendo-badge-padding-x: map-get( $spacing, 1 ) !default;
$kendo-badge-padding-x-sm: map-get($spacing, 1) !default;
$kendo-badge-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-badge-padding-x-lg: map-get( $spacing, 2 ) !default;

/// Vertical padding of the badge.
/// @group badge
$kendo-badge-padding-y: map-get( $spacing, 1 ) !default;
$kendo-badge-padding-y-sm: map-get( $spacing, hair) !default;
$kendo-badge-padding-y-md: $kendo-badge-padding-y -  map-get( $spacing, hair) !default;
$kendo-badge-padding-y-lg: map-get( $spacing, 1 ) + map-get( $spacing, hair ) !default;

/// Font sizes of the badge.
/// @group badge
$kendo-badge-font-size: $font-size-thin !default;
$kendo-badge-font-size-sm: $kendo-badge-font-size !default;
$kendo-badge-font-size-md: $kendo-badge-font-size !default;
$kendo-badge-font-size-lg: $kendo-badge-font-size !default;

/// Line heights used along with $font-size.
/// @group badge
$kendo-badge-line-height: 1.2 !default;
// $kendo-badge-line-height-sm: $kendo-badge-line-height !default;
// $kendo-badge-line-height-md: $kendo-badge-line-height !default;
// $kendo-badge-line-height-lg: $kendo-badge-line-height !default;

/// Badge min width used for circle badge.
/// @group badge
// $kendo-badge-min-width: calc( #{$kendo-badge-line-height * 1em} + #{$kendo-badge-padding-y * 2} + #{$kendo-badge-border-width * 2}) !default;
// $kendo-badge-min-width-sm: calc( #{$kendo-badge-line-height-sm * 1em} + #{$kendo-badge-padding-y-sm * 2} + #{$kendo-badge-border-width * 2} ) !default;
// $kendo-badge-min-width-md: calc( #{$kendo-badge-line-height-md * 1em} + #{$kendo-badge-padding-y-md * 2} + #{$kendo-badge-border-width * 2} ) !default;
// $kendo-badge-min-width-lg: calc( #{$kendo-badge-line-height-lg * 1em} + #{$kendo-badge-padding-y-lg * 2} + #{$kendo-badge-border-width * 2} ) !default;

/// Sizes map for the badge.
/// @group badge
// $kendo-badge-sizes: (
//     sm: (
//         padding-x: $kendo-badge-padding-x-sm,
//         padding-y: $kendo-badge-padding-y-sm,
//         font-size: $kendo-badge-font-size-sm,
//         line-height: $kendo-badge-line-height-sm,
//         min-width: $kendo-badge-min-width-sm
//     ),
//     md: (
//         padding-x: $kendo-badge-padding-x-md,
//         padding-y: $kendo-badge-padding-y-md,
//         font-size: $kendo-badge-font-size-md,
//         line-height: $kendo-badge-line-height-md,
//         min-width: $kendo-badge-min-width-md
//     ),
//     lg: (
//         padding-x: $kendo-badge-padding-x-lg,
//         padding-y: $kendo-badge-padding-y-lg,
//         font-size: $kendo-badge-font-size-lg,
//         line-height: $kendo-badge-line-height-lg,
//         min-width: $kendo-badge-min-width-lg
//     )
// ) !default;



// Custom variables

$badge-border-radius-md: 4px !default;
$badge-bg-color-teal: rgba(14, 117, 125, 0.2) !default;
$badge-color-teal: $button-text !default;
$badge-border-color-teal: 1px solid #0E757D !default;
$badge-border-radius-pill: 24px !default;

$badge-sm-height: 16px;
$badge-md-height: 20px;
$badge-lg-height: 24px;

// Dot badge

$dot-badge-size-sm: 8px;
$dot-badge-size-md: 10px;
$dot-badge-size-lg: 12px;