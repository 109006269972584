    // Wrapper
    .k-tabstrip-wrapper {
        @include fill(
            $tabstrip-wrapper-text,
            $tabstrip-wrapper-bg,
            $tabstrip-wrapper-border
        );
        
    }


    // Items
    .k-tabstrip-items-wrapper {
        @include fill(
            $tabstrip-text,
            $tabstrip-bg,
            $tabstrip-border
        );
       
        .k-item {
            @include fill(
                $tabstrip-item-text,
                //$tabstrip-item-bg,
                null,
                $tabstrip-item-gradient
            );
            font-weight: $tabstrip-font-weight;
            //border-bottom: $tabstrip-item-border;
            

            &:hover,
            &.k-hover {
                @include fill(
                    $tabstrip-item-hovered-text,
                    $tabstrip-item-hovered-bg,
                    null,
                    $tabstrip-item-hovered-gradient
                );
                border-bottom: $tabstrip-item-hovered-border;
            }

            &:active,
            &.k-active,
            &.k-selected {
                @include fill(
                    $tabstrip-item-selected-text,
                    $tabstrip-item-selected-bg,
                    null,
                    $tabstrip-item-selected-gradient
                );
                border-bottom: $tabstrip-item-selected-border;
                border-radius: $tabstrip-item-selected-border-radius;
            }

            &:focus,
            &.k-focus {
                @include box-shadow( $tabstrip-item-focused-shadow );
            }

            &.k-tabstrip-dragging {
                @include box-shadow( $tabstrip-item-dragging-shadow );
            }
        }

    }


    // Content
    .k-tabstrip-content,
    .k-tabstrip > .k-content {
        @include fill(
            $tabstrip-content-text,
            $tabstrip-content-bg,
            $tabstrip-content-border
        );
        border: $tabstrip-content-border-none;

        &:focus,
        &.k-focus {
            outline-color: $tabstrip-content-border-focused;
        }
    }


    // Selected indicator
    @if ($tabstrip-indicator-size) {

        .k-tabstrip-items-wrapper {
            .k-item.k-active::after {
                border-color: $tabstrip-indicator-color;
            }
        }
    }


