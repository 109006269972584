$primary: #0079C1 !default;
$primary-darker:linear-gradient(0deg, rgba(15, 34, 88, 0.16), rgba(15, 34, 88, 0.16)), #0079C1 !default;
$secondaryblue: #0097E9 !default;
$teal: #0E757D !default;
$purple: #7F85DC !default;
$orange: #F68827 !default;
//$link-hover: linear-gradient(0deg, rgba(15, 34, 88, 0.16), rgba(15, 34, 88, 0.16)), #0079C1 !default;
$component-text-white: #FFFFFF !default;
$component-text: #0B1E25 !default;
$component-border: #C2C2C5 !default;
$error: #D71616 !default;
$error: #D71616 !default;
$info: $primary !default;
$success: #389645 !default;
$warning: #EEC51A !default;
$base-bg: #EEEEF0 !default;
$hovered-bg: rgba(165, 192, 207, 0.25) !default;
$hovered-border: rgba(15, 34, 88, 0.24) !default;
$disabled-text: #86898B !default;
$subtle-text: #636F92 !default;
$placeholder-text: #717174 !default;
$base-text: #0B1E25 !default;
$secondary: #636F92 !default;
$dark: #424242 !default;
$black: #000000 !default;
$black-16-percent: rgba(16, 24, 32, 0.16) !default;
$black-75-percent: rgba(16, 24, 32, 0.75) !default;
$light: #EBEBEB !default;
$tertiary: #03A9F4 !default;
$scheduler-event-bg: #FF7C73 !default;
$button_bg: #FAFBFC !default;
$secondary_button_bg: $button_bg !default;
$success:#37b400 !default;
$success-16-percent: rgba($success, 0.16);
$inverse: #424242 !default;
$grid-alternate: #f1f1f3 !default;
$grid-sorted: rgba($component-text, 0.03) !default;
$button-text:#0F2258 !default;
$grid-focused-shadow: inset 0px 0px 0px 2px rgba(22, 155, 215, 0.3) !default;
$grid-selected: rgba($secondaryblue, 0.16) !default;
$grid-alt-selected: #D6ECF6;
$grid-highlighted: rgba($secondaryblue, 0.04);
$pills-teal-bg: rgba($teal, 0.16) !default;
$pills-purple-bg: rgba($purple, 0.16) !default;
$pills-succss-bg: $success-16-percent;
$white: #ffffff;
$drawer-box-shadow:rgb(15 34 88 / 4%) 0px 4px 8px, rgb(15 34 88 / 8%) 0px 4px 16px;
$login-dialog-box-shodow: 0px 4px 16px 0px rgba(15, 34, 88, 0.08);
$notification-unread-bg: rgba(49, 131, 72, 0.08);
$horizontal-bar-border: 1px solid rgba(15, 34, 88, 0.16);
$darkgrey: #0E1B25 !default;
$box-shadow-button: inset 0 -3px 0 0 #0079C1;
$box-shadow-button-focused: inset 0 -4.5rem 0 0 #0079C1;
$horizontal-bar-border: 1px solid $component-border;
$card-border: rgba(#0F2258, 0.16);
$card-box-shadow: 0px 4px 16px 0px rgba(11, 30, 37, 0.08), 0px 4px 8px 0px rgba(11, 30, 37, 0.04);
$stepper-current-label-box-shadow: inset 0px 0px 0px 2px rgba(0, 151, 233, 0.30);
$readonly-field-bg: none;
$page-bg: #F7F7F7;
$listview-item-bg : rgba(11, 30, 37, 0.04);
$black-75 : rgba(16, 24, 32, 0.75);
$purple: #5B60AB;
$amber-row-color: linear-gradient(0deg, #F688271A 0%, #F688271A 100%), linear-gradient(0deg, #EEC51A26 0%, #EEC51A26 100%), #F1F1F3;
$amber-row-alt-color: linear-gradient(0deg, #F688271A 0%, #F688271A 100%), linear-gradient(0deg, #EEC51A26 0%, #EEC51A26 100%), #FFF;