williams-ui-platform-notice-card {
    .badge-wrapper {
        height: 26px;
        position: relative;
    }

    .k-card-header {
        @extend .w-p-5;
    }

    .k-card-body {
        @extend .w-px-5;
        @extend .w-pt-1;
    }

    .k-card-footer {
        @extend .w-pt-0;
    }
}
.card-shadow-base {
    box-shadow: $card-box-shadow;
    background-color: $component-bg;
    overflow: hidden;
}

.card-shadow {
    border: 1px solid $card-border;
    @extend .card-shadow-base;
}
.card-rounded {
    border-radius: $kendo-border-radius-thin;
}

.card-rounded-lg {
    border-radius: $kendo-border-radius-large;
}

.card-padding-none {
    .k-card-header {
        @extend .w-p-0;
    }
    .k-card-body {
        @extend .w-p-0;
    }
}
.card-border-none {
        border: none;
}

[cardtitle] {
    font-size: $card-title-font-size;
    font-weight: $card-title-font-weight;
}

[cardSubTitle]{
    font-size: $card-subtitle-font-size;
    color: $card-subtitle-text;
    font-weight: $card-subtitle-font-weight;
}

.card-bg-transparent {
    background-color: transparent;
}