.k-drawer {
  box-shadow: $drawer-box-shadow;
  @extend .w-pt-14;
}

.k-drawer-mini .k-drawer {
  background-color: $drawer-mini-bg;
  color: $drawer-mini-text;
}

.k-drawer-item {
  &.k-level-0 {
    font-weight: $font-weight-bold;
  }
}

.k-drawer-container {
  height: 100%;
}

.k-drawer-content {
  background: $page-bg;
}

.k-drawer-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.k-drawer-items {
  flex: 1;
  overflow-y: auto;
}

.k-drawer-item .k-item-text {
  white-space: initial;
  overflow: initial;
  text-overflow: initial;

}

// Primary Theme(custom implemented)
.k-drawer-primary {
    @include wFill(
      $drawer-text-primary,
      $drawer-bg-primary
    );
    
    .k-drawer-wrapper {
      padding-left: map-get($spacing, 5);
      padding-right: map-get($spacing, 5);
    }
    
    .k-drawer-item {

      &:hover,
      &.k-hover {
        color: $drawer-hovered-text-primary;
        background: $drawer-hover-bg-primary;
      }
    
      &:focus,
      &.k-focus {
        background-color: $drawer-focused-bg-primary;
        box-shadow: none;
    
        &:hover,
        &.k-hover {
          color: $drawer-hovered-text-primary;
          background: $drawer-hover-bg-primary;
        }
      }
    
      &.k-selected {
        color: $drawer-selected-text-primary;
        background: $drawer-selected-bg-primary;
    
        &:hover,
        &.k-hover {
          color: $drawer-selected-hover-text-primary;
          background: $drawer-selected-hover-bg-primary;
        }
      }
    }
  } 
  //** Mini Drawer Related Changes
  .clickable {
    cursor: pointer;
  }
  
  .drawerPanel {
    div {
      width:  map-get($spacing, 10);
      height: map-get($spacing, 10);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:hover {
      background: rgba(0, 121, 193, 0.12);
      border-radius: map-get($spacing, 10);
    }
  }