kendo-dropdownlist {
    .k-input-inner {
        .k-input-value-text {
            display: flex;
        }
    }
    .dropdownErrorIcon {
        position: absolute;
        right: 0px;
    }
}

// Updating styles of options in  all dropdowns - ( combobox, multiselect, dripdown )
kendo-popup .k-list-ul {
    li {
      background-color: $white;
  
      &:nth-child(even) {
        background-color: $grid-alternate;
      }
  
      &:hover {
        background-color: $hovered-bg;
      }
  
      &.k-selected {
        background-color: $primary;
      }
    }
  }