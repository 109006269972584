// The border radius for all components.
$kendo-border-radius: 2px !default;
// The background of the components' chrome area.
$kendo-base-bg: #FFFFFF !default;
// The text color of the components' chrome area.
// $kendo-base-text: $component-text;
$kendo-base-text: $base-text !default;
// The border color of the components' chrome area.
// $kendo-base-border	
// The gradient background of the components' chrome area.
// $kendo-base-gradient	
// The background of hovered items.
// $kendo-hover-bg	
// The text color of hovered items.
// $kendo-hover-text	
// The border color of hovered items.
// $kendo-hover-border	
// The gradient background of hovered items.
// $kendo-hover-gradient	
// The text color of selected items.
// $kendo-selected-text	
// The background of selected items.
// $kendo-selected-bg	
// The border color of selected items.
// $kendo-selected-border	
// The gradient background of selected items.
// $kendo-selected-gradient	


$subtle-text: $placeholder-text !default;
// Component

$component-text: $base-text !default;
$component-bg: #FFFFFF !default;
$empty-message-bg: rgba(0, 121, 193, 0.08); 

/**
border radius for small ,medium,large type components
*/
$kendo-border-radius-xsm:12px;
// $kendo-border-radius-sm: 28px !default;
$kendo-border-radius-md: 2px !default;
//$kendo-border-radius-lg: 40px !default;
$kendo-border-radius-thin : 4px !default;
$kendo-border-radius-large : 12px !default;



// Spacing

$spacing: (
    0: 0,
    1: 4px,
    2: 8px,
    3: 12px,
    4: 16px,
    5: 20px,
    6: 24px,
    7: 28px,
    8: 32px,
    9: 36px,
    10: 40px,
    11: 44px,
    12: 48px,
    13: 52px,
    14: 56px,
    15: 60px,
    16: 64px,
    17: 68px,
    18: 72px,
    19: 76px,
    20: 80px,
    21: 84px,
    22: 88px,
    23: 92px,
    24: 96px,
    xs: 4px,
    sm: 8px,
    md: 12px,
    lg: 16px,
    xl: 24px,
    thin: 2px,
    hair: 1px,
    auto:auto
) !default;
$opacitylevels: (0: 0, pt1: .1, pt2: .2, pt3: .3,pt4: .4,pt5: .5,pt6: .6,pt7: .7,pt8: .8,pt9: .9,1: 1) !default;

// flex
$flex: (
    0:0.2,
    1: 0.4,
    2:0.6,
    3:0.8,
    4:1,
    5:1.2,
    6:1.4,
    7:1.6,
    8:1.8,
    9:2.0
);
$flex-basis: (
   0, 1, 2, 3, 4, 5 , 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,  26, 27, 28, 29, 30
);

$kendo-theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "info": $info,
    "success": $success,
    "warning": $warning,
    "error": $error,
    "dark": $dark,
    "light": $light,
    "inverse": $inverse,
    "white": $white,
    "compTxt": $component-text,
    "subTitle": $subtle-text,
    "btnTxt":$button-text,
    "disable": $disabled-text,
    "purple": $purple,
    "baseBg": $base-bg
) !default;

$display-none : none !important;
$display-block : block !important;
$display-inline : inline !important;
$display-inline-block : inline-block !important;
