.k-scrollview-next,
.k-scrollview-prev {
    color: $scrollview-navigation-color;
    background-color: $scrollview-navigation-bg;
    text-shadow: $scrollview-navigation-icon-shadow;
    opacity: $scrollview-navigation-default-opacity;
    border-radius: $scrollview-navigation-border-radius;
    width: $scrollview-navigation-width;
    height: $scrollview-navigation-height;
    outline-width: 0;
    box-sizing: $scrollview-navigation-box-sizing;
/* Auto layout */
    display: $scrollview-navigation-flex;
    flex-direction: $scrollview-navigation-row;
    justify-content: $scrollview-navigation-center;
    align-items: $scrollview-navigation-center;
    padding: $scrollview-navigation-padding;
    margin-top: $scrollview-navigation-margin-top;
    -webkit-tap-highlight-color: $scrollview-arrow-tap-highlight-color;

    &:focus,
    &.k-focus {
        color: $scrollview-navigation-color;
        opacity: $scrollview-navigation-hover-opacity;

        .k-icon::before {
            box-shadow: $scrollview-pagebutton-shadow;
        }
    }

    &:hover,
    &.k-hover {
        color: $scrollview-navigation-color;
        opacity: $scrollview-navigation-hover-opacity;

    }
    .k-i-chevron-right,
    .k-i-chevron-left
    {
        font-size: $scrollview-navigation-font-size;
    }
    .k-i-chevron-right::before { 
        content: $scrollview-navigation-icon-right;
      
     }
    .k-i-chevron-left::before { content: $scrollview-navigation-icon-left; }
}
