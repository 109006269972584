.k-expander-header{
  display: block;
  cursor: default;
}

.k-expander-indicator {
  display: none;
}

.k-expander-content {
  @extend .w-pt-2;
}