williams-ui-platform-sidebar{
    williams-ui-platform-window {
        .k-window-titlebar {
            @include wFill($sidebar-titlebar-text, $sidebar-titlebar-bg);
            border-width: 0px;
            padding: $sidebar-titlebar-padding;
        }
        .k-window {
            position: fixed !important;
            border-radius: $sidebar-border-radius;
            box-shadow: $sidebar-focused-shadow;
            border-width: 0;
            padding: $sidebar-window-padding-y $sidebar-window-padding-x;
            left: initial !important;
            right: 0;
            z-index: 10 !important;
        }
        .k-window-content {
            padding: 0;
            display: flex;
            flex-direction: column;

            &:last-child {
                padding-bottom: 0;    
            }

            [content] {
                flex-grow: 1;
                padding: $sidebar-content-padding;
            }
        }
    }
}