body {
  color: $component-text;
}
.no-style-on-anchor{
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
a.nostyle:link {
  @extend .no-style-on-anchor;
}

a.nostyle:visited {
  @extend .no-style-on-anchor;
}

@each $index,
$val in $flex {
  .w-flex-#{$index} {
    flex: $val
  }
}

@each $value in $flex-basis {
  .w-flex-basis-#{$value} {
    flex-basis: $value*4*10px;
  }
  .w-width-#{$value} {
    width: $value*4*10px !important;
  }
  .w-height-#{$value} {
    height: $value*4px !important;
  }
}

@for $value from 0 through 100 {
.w-flex-basis-p-#{$value} {
  flex-basis: $value * 1%;
}
.w-pt-p#{$value} {
  padding-top: $value * 1%;
}
.w-pb-p#{$value} {
  padding-bottom: $value * 1%;
}
}

/* Text Property */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.underline{
  text-decoration: underline;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* overflow property */
.overflow-hidden {
  overflow: hidden;
}
.w-visibility-hidden{
  visibility: hidden;
}

.overflow-y {
  overflow-y: auto
}

.overflow-auto {
  overflow: auto
}

.overflow-unset{
  overflow: unset !important;
}

.box-shadow {
  box-shadow: $login-dialog-box-shodow;
  border-radius: $kendo-border-radius-xsm;
}

.border-radius-xsm {
  border-radius: $kendo-border-radius-xsm;
}

.border-radius-lg {
  border-radius: 4px;
}

.border-radius-md {
border-radius: $kendo-border-radius-md;
}

.w-comp-border {
  border: 1px solid $component-border;
}

.transform-stack-20 {
  transform: translateX(20%);
}

.vertical-bar{
  width: 1px;
  background: $component-border;
  height: map-get($spacing, 5)
}
.horizontal-line{
  border: $horizontal-bar-border;
}

.padding-top-15per{
  padding-top: 15%;
}

.cursor-pointer {
  cursor: pointer;
}
.remove-border{
  border:none;
}
.float-right {
  float: right;
}

.w-flex-1 {
  flex: 1;
}

.w-width-100{
  width: 100%;
}

.w-height-100 {
  height: 100%;
}

.w-height-inherit {
  height: inherit;
}


.inactive-error-message {
  text-align: center;
  background-image:  url(../../../../images/WilliamsSupergraphic.png);
  background-repeat: no-repeat;
}

.position-fixed {
  position: fixed;
}


.page-wrapper-padding {
  @extend .w-p-4;
}

.search-criteria-padding {
  @extend .w-p-3;
}

mat-icon.mat-icon {
  height: auto;
  width: auto;
  font-size: 16px;
}

.w-bordered {
  border: 1px solid $component-border;
}

.w-border-radius-lg {
  border-radius: 4px;
  overflow: hidden;
}

.w-color-placeholder {
  color: $placeholder-text;
}

.w-display-none {
  display: none;
}

.w-display-block {
  display: block;
}