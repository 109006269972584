@each $color-name, $color in $kendo-theme-colors {
  @each $index, $level in $opacitylevels {
    .w-bg-#{$color-name}-#{$index} {
        background-color: $color;
        opacity: $level;
    }
    .w-color-#{$color-name}-#{$index} {
        color: $color;
        opacity: $level;
    }
  }
}
