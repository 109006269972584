@each $color-name, $color in $kendo-theme-colors {
    .w-messagebox-#{$color-name} {
        @include fill(
            color-level( $color, $message-box-text-level ),
            color-level( $color, $message-box-bg-level ),
            color-level( $color, $message-box-border-level )
        );
    }
}

.w-messagebox {
    margin: $message-box-margin;
    padding: $message-box-padding-y $message-box-padding-x;
    border-width: $message-box-border-width;
    border-style: solid;
    box-sizing: border-box;
    font-size: $message-box-font-size;
    line-height: $message-box-line-height;

    a {
        color: inherit;
        font-style: $message-box-link-font-style;
        text-decoration: $message-box-link-text-decoration;
    }
}

.error-wrapper {
    width: $error-msg-box-width;
    background: $error-msg-box-background-color;
    border: $error-msg-box-border;
    border-radius: map-get($spacing, 1);
    padding:  map-get($spacing,2) map-get($spacing,3);
    color: $error;
    .label {
        margin: 0;
    }
    .error-list {
       list-style: none;
       li::before {
        content: "\2022";
        display: inline-block; 
        width: map-get($spacing , 4 );
        margin-left: calc(-1*map-get($spacing , 4 ));
      }
      .removeListType::before{
        content: "";
      }
    }
}

.notify-wrapper {
  width: $notify-msg-box-width;
  background: $notify-msg-box-background-color;
  border: $notify-msg-box-border;
  border-radius: map-get($spacing, 1);
  padding:  map-get($spacing,2) map-get($spacing,3);
  color: $orange;
  .label {
    margin: 0;
  }
  .error-list {
    list-style: none;
    li::before {
      content: "\2022";
      display: inline-block;
      width: map-get($spacing , 4 );
      margin-left: calc(-1*map-get($spacing , 4 ));
    }
    .removeListType::before{
      content: "";
    }
  }
}
