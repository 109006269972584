// Typography

/// Global typography styles for the Kendo Default theme.
/// @group typography
$enable-typography: true !default;

/// Base font size across all components.
/// @group typography
$font-size: 14px !default;
$font-size-xs: 12px !default;
$font-size-sm: $font-size !default;
$font-size-md: $font-size !default;
$font-size-lg: 16px !default;
$font-size-sxl: 18px;
$font-size-xl: 20px !default;
$font-size-thin : 10px !default;

// Font sizes


/// Font family across all components.
/// @group typography
$font-family: 'Helvetica' !default;

/// Font family for monospaced text. Used for styling the code.
/// @group typography
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Roboto Mono", "Ubuntu Mono", "Lucida Console", "Courier New", monospace !default;

/// Line height used along with $font-size.
/// @group typography
$line-height: 1.4286 !default;
$line-height-xs: 1 !default;
$line-height-sm: $line-height !default;
$line-height-md: $line-height !default;
$line-height-lg: 1.5 !default;
$line-height-em: calc( #{$line-height} * 1em ) !default;

// $line-height: (20 / 14) !default;
// $line-height-xs: 1 !default;
// $line-height-sm: 1.25 !default;
// $line-height-md: $line-height !default;
// $line-height-lg: 1.5 !default;
// $line-height-em: calc( #{$line-height} * 1em ) !default;

// Font weight
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;


// Letter Spacing
$letter-spacing: null !default;


// Headings
$h1-font-size: 28px !default;
$h2-font-size: 25px !default;
$h3-font-size: 22px !default;
$h4-font-size: 20px !default;
$h5-font-size: 18px !default;
$h6-font-size: 16px !default;

// $h1-font-size: ($font-size * 3) !default;
// $h2-font-size: 34px !default;
// $h3-font-size: ($font-size * 2) !default;
// $h4-font-size: 22px !default;
// $h5-font-size: $font-size-lg !default;
// $h6-font-size: $font-size !default;

$h1-font-family: $font-family !default;
$h2-font-family: $font-family !default;
$h3-font-family: $font-family !default;
$h4-font-family: $font-family !default;
$h5-font-family: $font-family !default;
$h6-font-family: $font-family !default;

$h1-line-height: 28px !default;
$h2-line-height: 28px !default;
$h3-line-height: 28px !default;
$h4-line-height: 24px !default;
$h5-line-height: 24px !default;
$h6-line-height: 20px !default;

// $h1-line-height: 54px !default;
// $h2-line-height: 46px !default;
// $h3-line-height: 42px !default;
// $h4-line-height: 32px !default;
// $h5-line-height: 26px !default;
// $h6-line-height: 20px !default;

$h1-font-weight: $font-weight-normal !default;
$h1-font-weight-bold: $font-weight-bold !default;
$h2-font-weight: $font-weight-normal !default;
$h3-font-weight: $font-weight-bold !default;
$h4-font-weight: $font-weight-bold !default;
$h5-font-weight: $font-weight-normal !default;
$h6-font-weight: $font-weight-normal !default;

// $h1-font-weight: $font-weight-normal !default;
// $h2-font-weight: $font-weight-normal !default;
// $h3-font-weight: $font-weight-normal !default;
// $h4-font-weight: $font-weight-bold !default;
// $h5-font-weight: $font-weight-bold !default;
// $h6-font-weight: $font-weight-bold !default;

$h1-letter-spacing: null !default;
$h2-letter-spacing: null !default;
$h3-letter-spacing: null !default;
$h4-letter-spacing: null !default;
$h5-letter-spacing: null !default;
$h6-letter-spacing: null !default;

$h1-margin: 0 0 $font-size !default;
$h2-margin: 0 0 $font-size !default;
$h3-margin: 0 0 $font-size !default;
$h4-margin: 0 0 $font-size !default;
$h5-margin: 0 0 $font-size !default;
$h6-margin: 0 0 $font-size !default;


// Paragraph
$paragraph-margin: 0 0 $font-size !default;
$paragraph-font-size: $font-size !default;
$paragraph-font-family: $font-family !default;
$paragraph-line-height: 20px !default;
$paragraph-font-weight: $font-weight-normal !default;
$paragraph-letter-spacing: null !default;


// Code
$code-font-size: $font-size !default;
$code-font-family: $font-family-monospace !default;
$code-line-height: 20px !default;
$code-font-weight: $font-weight-normal !default;
$code-letter-spacing: null !default;

$code-padding-x: 4px !default;
$code-padding-y: 0px !default;
$pre-padding-x: 24px !default;
$pre-padding-y: 16px !default;
$code-border-width: 1px !default;

$code-bg: $base-bg !default;
$code-text: $component-text !default;
$code-border: $component-border !default;

// Link
$link-text: $primary !default;
$link-hover-text: $primary-darker !default; 


// Display
$display1-font-size: 45px !default;
$display2-font-size: 40px !default;
$display3-font-size: 36px !default;
$display4-font-size: 32px !default;
$display5-font-size: 28px;
// $display1-font-size: ($font-size * 6) !default;
// $display2-font-size: ($font-size * 5) !default;
// $display3-font-size: ($font-size * 4) !default;
// $display4-font-size: ($font-size * 3) !default;



$display1-font-family: $font-family !default;
$display2-font-family: $font-family !default;
$display3-font-family: $font-family !default;
$display4-font-family: $font-family !default;

$display1-line-height: 56px !default;
$display2-line-height: 48px !default;
$display3-line-height: 44px !default;
$display4-line-height: 40px !default;
// $display1-line-height: 1.2 !default;
// $display2-line-height: 1.2 !default;
// $display3-line-height: 1.2 !default;
// $display4-line-height: 1.2 !default;

$display1-font-weight: $font-weight-light !default;
$display2-font-weight: $font-weight-light !default;
$display3-font-weight: $font-weight-light !default;
$display4-font-weight: $font-weight-light !default;

$display1-letter-spacing: null !default;
$display2-letter-spacing: null !default;
$display3-letter-spacing: null !default;
$display4-letter-spacing: null !default;

$font-style-italic : italic;