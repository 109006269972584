// Input
$kendo-input-text: $component-text !default;
/// Default width of input components.
/// @group input
// $kendo-input-default-width: 100%;

/// Border width of input components.
/// @group input
// $kendo-input-border-width: 1px !default;
// $kendo-input-border-height: ( $kendo-input-border-width * 2 ) !default;
/// Border radius of input components.
/// @group input
$kendo-input-border-radius: 2px !default;

/// Horizontal padding of input components.
/// @group input
// $kendo-input-padding-x: map-get( $spacing, 2 ) !default;
/// Vertical padding of input components.
/// @group input
// $kendo-input-padding-y: map-get( $spacing, 1 ) !default;
/// Font family of input components.
/// @group input
// $kendo-input-font-family: $font-family !default;
/// Font size of input components.
/// @group input
// $kendo-input-font-size: $font-size-md !default;
/// Line height of input components.
/// @group input
// $kendo-input-line-height: $line-height-md !default;

// $kendo-input-padding-x-sm: map-get( $spacing, 2 ) !default;
$kendo-input-padding-y-sm: map-get( $spacing, hair );
// $kendo-input-font-size-sm: $font-size-md !default;
// $kendo-input-line-height-sm: $line-height-md !default;

// $kendo-input-padding-x-md: map-get( $spacing, 2 ) !default;
$kendo-input-padding-y-md: map-get( $spacing, 1 ) - map-get( $spacing, hair ) !default;
// $kendo-input-font-size-md: $font-size-md !default;
// $kendo-input-line-height-md: $line-height-md !default;

$kendo-input-padding-x-lg: map-get( $spacing, 2 ) !default;
$kendo-input-padding-y-lg: map-get( $spacing, 1 ) - map-get( $spacing, hair ) !default;
// $kendo-input-font-size-lg: $font-size-lg !default;
// $kendo-input-line-height-lg: $line-height-lg !default;

// $kendo-input-sizes: (
//     sm: (
//         padding-x: $kendo-input-padding-x-sm,
//         padding-y: $kendo-input-padding-y-sm,
//         font-size: $kendo-input-font-size-sm,
//         line-height: $kendo-input-line-height-sm,
//         icon-size: calc( #{$icon-size} + #{$kendo-input-padding-y-sm * 2} ),
//         button-padding-x: $kendo-input-padding-y-sm,
//         button-padding-y: $kendo-input-padding-y-sm
//     ),
//     md: (
//         padding-x: $kendo-input-padding-x-md,
//         padding-y: $kendo-input-padding-y-md,
//         font-size: $kendo-input-font-size-md,
//         line-height: $kendo-input-line-height-md,
//         icon-size: calc( #{$icon-size} + #{$kendo-input-padding-y-md * 2} ),
//         button-padding-x: $kendo-input-padding-y-md,
//         button-padding-y: $kendo-input-padding-y-md
//     ),
//     lg: (
//         padding-x: $kendo-input-padding-x-lg,
//         padding-y: $kendo-input-padding-y-lg,
//         font-size: $kendo-input-font-size-lg,
//         line-height: $kendo-input-line-height-lg,
//         icon-size: calc( #{$icon-size} + #{$kendo-input-padding-y-lg * 2} ),
//         button-padding-x: $kendo-input-padding-y-lg,
//         button-padding-y: $kendo-input-padding-y-lg
//     )
// ) !default;

// $kendo-input-bg: $component-bg !default;
// $kendo-input-text: $component-text !default;
// $kendo-input-border: $component-border !default;
// $kendo-input-shadow: null !default;

// $kendo-input-hover-bg: null !default;
// $kendo-input-hover-text: null !default;
$kendo-input-hover-border: rgba( $component-text, .24 ) !default;
// $kendo-input-hover-shadow: null !default;

// $kendo-input-focus-bg: null !default;
// $kendo-input-focus-text: null !default;
// $kendo-input-focus-border: $kendo-input-hover-border !default;
$kendo-input-focus-shadow: 0px 0px 0px 2px rgba(22, 155, 215, 0.3) !default;

// $kendo-input-disabled-bg: null !default;
// $kendo-input-disabled-text: null !default;
// $kendo-input-disabled-border: null !default;
// $kendo-input-disabled-gradient: null !default;
// $kendo-input-disabled-shadow: null !default;

// $kendo-input-outline-bg: null !default;
// $kendo-input-outline-text: $kendo-input-text !default;
$kendo-input-outline-border: rgba( $kendo-input-text, .5) !default;

// $kendo-input-outline-hover-bg: null !default;
// $kendo-input-outline-hover-text: null !default;
$kendo-input-outline-hover-border: rgba( $kendo-input-text, .8) !default;

// $kendo-input-outline-focus-bg: null !default;
// $kendo-input-outline-focus-text: null !default;
// $kendo-input-outline-focus-border: null !default;
// $kendo-input-outline-focus-shadow: $kendo-input-focus-shadow !default;

// $kendo-input-flat-bg: null !default;
// $kendo-input-flat-text: $kendo-input-text !default;
// $kendo-input-flat-border: $kendo-input-border !default;

// $kendo-input-flat-hover-bg: null !default;
// $kendo-input-flat-hover-text: null !default;
// $kendo-input-flat-hover-border: $kendo-input-hover-border !default;

// $kendo-input-flat-focus-bg: null !default;
// $kendo-input-flat-focus-text: null !default;
$kendo-input-flat-focus-border: rgba( $component-text, .24 ) !default;
// $kendo-input-flat-focus-shadow: $kendo-input-focus-shadow !default;


$kendo-picker-bg: $component-bg !default;
// $kendo-picker-text: $kendo-button-text !default;
$kendo-picker-border: $component-border !default;
// $kendo-picker-gradient: $kendo-button-gradient !default;
// $kendo-picker-shadow: $kendo-button-shadow !default;

$kendo-picker-hover-bg: $kendo-picker-bg !default;
// $kendo-picker-hover-text: $kendo-button-hover-text !default;
$kendo-picker-hover-border: $kendo-input-hover-border !default;
// $kendo-picker-hover-gradient: $kendo-button-hover-gradient !default;
// $kendo-picker-hover-shadow: $kendo-button-hover-shadow !default;

// $kendo-picker-focus-bg: $kendo-button-focus-bg !default;
// $kendo-picker-focus-text: $kendo-button-focus-text !default;
// $kendo-picker-focus-border: $kendo-button-focus-border !default;
// $kendo-picker-focus-gradient: $kendo-button-focus-gradient !default;
$kendo-picker-focus-shadow: $kendo-input-focus-shadow !default;

// $kendo-picker-disabled-bg: null !default;
// $kendo-picker-disabled-text: null !default;
// $kendo-picker-disabled-border: null !default;
// $kendo-picker-disabled-gradient: null !default;
// $kendo-picker-disabled-shadow: null !default;

// $kendo-picker-outline-bg: null !default;
$kendo-picker-outline-text: $component-text !default;
$kendo-picker-outline-border: rgba( $kendo-picker-outline-text, .5) !default;

$kendo-picker-outline-hover-bg: $kendo-picker-outline-text !default;
$kendo-picker-outline-hover-text: $kendo-input-text !default;
$kendo-picker-outline-hover-border: $kendo-picker-outline-hover-bg !default;

// $kendo-picker-outline-focus-bg: null !default;
// $kendo-picker-outline-focus-text: null !default;
// $kendo-picker-outline-focus-border: null !default;
// $kendo-picker-outline-focus-shadow: $kendo-picker-focus-shadow !default;

// $kendo-picker-outline-hover-focus-bg: null !default;
// $kendo-picker-outline-hover-focus-text: null !default;
// $kendo-picker-outline-hover-focus-border: null !default;

// $kendo-picker-flat-bg: null !default;
// $kendo-picker-flat-text: $kendo-button-text !default;
// $kendo-picker-flat-border: $kendo-button-border !default;

$kendo-picker-flat-hover-bg: rgba( $kendo-input-text, .04 ) !default;
// $kendo-picker-flat-hover-text: null !default;
// $kendo-picker-flat-hover-border: rgba( $kendo-button-border, .16 ) !default;

// $kendo-picker-flat-focus-bg: null !default;
// $kendo-picker-flat-focus-text: null !default;
// $kendo-picker-flat-focus-border: null !default;
// $kendo-picker-flat-focus-shadow: $kendo-picker-focus-shadow !default;

// $kendo-picker-flat-hover-focus-bg: null !default;
// $kendo-picker-flat-hover-focus-text: null !default;
// $kendo-picker-flat-hover-focus-border: null !default;

$kendo-input-placeholder-text: $placeholder-text !default;
// $kendo-input-placeholder-opacity: 1 !default;

// $kendo-input-selected-bg: null !default;
// $kendo-input-selected-text: null !default;

// $kendo-input-clear-value-text: null !default;
// $kendo-input-clear-value-opacity: .5 !default;
// $kendo-input-clear-value-hover-text: null !default;
// $kendo-input-clear-value-hover-opacity: 1 !default;

// $kendo-input-values-margin-y: map-get( $spacing, thin ) !default;
// $kendo-input-values-margin-x: $kendo-input-values-margin-y !default;


// Input actions
// $kendo-input-button-width: $kendo-button-inner-calc-size !default;
// $kendo-input-button-border-width: 1px !default;
// $kendo-input-spinner-width: $kendo-button-inner-calc-size !default;
// $kendo-input-spinner-icon-offset: null !default;


// Input separator
// $kendo-input-separator-color: $kendo-input-text !default;
// $kendo-input-separator-opacity: .5 !default;


// Invalid
// $kendo-input-invalid-border: $invalid-border !default;
// $kendo-input-invalid-shadow: $invalid-shadow !default;

$input-height-sm: 24px !default;
$input-height-md: 28px !default;
$input-height-lg: 32px !default;