.w-base-text-large-bold {
    @include typography($font-size-lg,null,24px,$font-weight-bold,null);
}
.w-text-large-normal {
    @include typography($font-size-lg,null,20px,$font-weight-normal,null);
}
.w-text-sm-normal {
    @include typography($font-size-sm,null,20px,$font-weight-normal,null);
}
.w-h1-bold {
    font-weight: $h1-font-weight-bold !important;
}
.w-small-text-bold {
    @include typography($font-size-xs,null,16px,$font-weight-bold,null);
}
.w-small-text-normal{
    @include typography($font-size-xs,null,16px,$font-weight-normal,null);
}

.w-medium-text-normal{
    @include typography($font-size,null,20px,$font-weight-normal,null);
}

.w-small-text-normal {
    @include typography($font-size-xs,null,16px,$font-weight-normal,null);
}
.w-base-text-slight-large-bold {
    @include typography($font-size-sxl,null,24px,$font-weight-bold,null);
}
.w-window-title {
    @include typography($font-size-sxl,null,24px,$font-weight-bold,null);
}
.w-font-italic{
    font-style: $font-style-italic ;
}
.w-font-xs-light{
    @include typography($font-size-xs,null,16px,$font-weight-light,null);
}
.w-display-3 {
    @include typography($display3-font-size, null, $display2-line-height, $font-weight-light, null);
}
.w-display-4 {
    @include typography($display4-font-size,null,40px,$font-weight-light,null);
}
.w-display-5 {
    @include typography($display5-font-size,null,32px,$font-weight-bold,null);
}
.w-hint-text {
    @include typography($font-size-xs,null,16px,$font-weight-light,null);
}
.w-base-text {
    @include  typography($font-size,null,20px,$font-weight-light,null);
}
.w-base-text-bold {
    @include  typography($font-size,null,20px,$font-weight-bold,null);
}
.w-base-pill-badge-medium {
    @include  typography($font-size - 4,null,12px,$font-weight-bold,null);
    letter-spacing: 1px;
}
.w-font-weight-normal {
    font-weight: $font-weight-normal !important;
}

.w-font-weight-bold {
    font-weight: $font-weight-bold !important;
}

.w-line-height-1 {
    line-height: 1;
}

.w-h4 {
    @extend .k-h4;
}